<div *ngIf="!showPageNewOverView" class="pl-provider" [hidden]="!allLoaded">
  <div *ngIf="!mayViewProvider" class="padding-large">
    <pl-permissions-denied [code]="permissionCode"></pl-permissions-denied>
  </div>
  <div [hidden]="!mayViewProvider">
    <div [ngClass]="{ 'outer-container': !newDesign }">
      <div class="header-container" *ngIf="!isDetailsRoute">
        <pl-provider-profile-header
          [provider]="provider"
        ></pl-provider-profile-header>
        <div class="tabs-container">
          <div class="tabs-wrapper">
            <pl-tabs
              [tabs]="tabs"
              [updatePageTabTitleBasedOnActiveLink]="true"
            ></pl-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!newDesign" class="legacy-spacer"></div>
</div>
<pl-provider-details-header
  *ngIf="showPageNewOverView"
  [provider]="providerNew"
  [userType]="userType"
></pl-provider-details-header>
<div class="provider-content-container">
  <div class="v2-inner">
    <router-outlet></router-outlet>
  </div>
</div>
